
import { defineComponent, onMounted, reactive } from "vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { SidebarModel, isSidebarOpen } from "./sidebar-model";
import { isDesktopLayout } from "@/data/providers/device-provider";

export default defineComponent({
	components: {
		FontAwesomeIcon
	},
	props: {
		title: {
			type: String
		},
		items: {
			type: Array
		}
	},
	setup() {
		const component: SidebarModel = reactive(new SidebarModel());

		onMounted(() => {
			component.init();
		});

		return { component, isDesktopLayout, isSidebarOpen };
	}
});
