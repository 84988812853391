<template>
  <component :is="this.$router.currentRoute.value.meta.layout ? this.$router.currentRoute.value.meta.layout : 'DefaultLayout'">
    <router-view />
  </component>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import DefaultLayout from "./layouts/default-layout.vue";
import BlankLayout from "./layouts/blank-layout.vue";

export default defineComponent({
	name: "App",
	components: {
		DefaultLayout,
		BlankLayout
	}
});
</script>
