
import { Guide } from "@/views/guide/guide-types";
import { computed, defineComponent, ComputedRef, Ref, ref, onMounted, watchEffect, nextTick } from "vue";
import { ComponentName } from "../constants";
import { SearchModel, searchTerm, isSearchOpen } from "./search-model";

export default defineComponent({
	name: ComponentName.Search,
	setup() {
		const component: SearchModel = new SearchModel();
		const search: Ref = ref();

		const filteredResults: ComputedRef<Array<Guide>> = computed((): Array<Guide> => {
			let filtered: Array<Guide> = component.guides;
			if (searchTerm.value) {
				filtered = filtered.filter((result: Guide) => {
					return result.title.toLowerCase().includes(searchTerm.value.toLowerCase()) || result.summary.toLowerCase().includes(searchTerm.value.toLowerCase()) || result.category.toLowerCase().includes(searchTerm.value.toLowerCase());
				});
			}
			return filtered;
		});

		onMounted(() => {
			watchEffect(() => {
				if (isSearchOpen.value) {
					nextTick(() => {
						search.value.focus();
					});
				}
			});
		});

		return { component, filteredResults, searchTerm, search, isSearchOpen };
	},
});
