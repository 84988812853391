/* eslint-disable @typescript-eslint/no-var-requires */
import { getLocalizedText } from "@/utilities/localization";
import { reactive } from "vue";
import { GuideState } from "./guide-state";
import * as marked from "marked";
import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";
import { mainContainer } from "@/main";

export class GuideModel {
	// Define state for guide page
	public state: GuideState = reactive(new GuideState);

	// Get Translated text
	public getText(key: string): string {
		return getLocalizedText("guide", key);
	}

	public init(): void {
		if (mainContainer.value) {
			const headings: NodeListOf<HTMLBodyElement> = mainContainer.value.querySelectorAll("h1, h2, h3, h4, h5, h6");
			mainContainer.value.addEventListener("scroll", () => {
				headings.forEach((heading: HTMLBodyElement) => {
					const headingTop: number = heading.offsetTop;
					if (mainContainer.value && ((mainContainer.value.scrollHeight - mainContainer.value.clientHeight) >= headingTop - 60)) {
						console.log(this.state.activeHeading);
						if (heading.id !== "") {
							this.state.activeHeading = `#${heading.id}`;
						}
						if (heading.id === "guide-title") {
							this.state.activeHeading = "";
						}
					}
				});
			});
		}
		if (location.hash) {
			location.href = location.hash;
		}
	}

	public setGuide(to: RouteLocationNormalized, next: NavigationGuardNext): void {
		this.state.guide = { title: "", summary: "", content: "", category: "" };
		this.state.guide = require(`@/content/${to.params.category}/${to.params.guide}/${to.params.section}/${to.params.section}.json`);
		this.state.guide.content = require(`@/content/${to.params.category}/${to.params.guide}/${to.params.section}/${to.params.section}.md`).default;
		this.getHeadings();
		const title: Element | null = window.document.head.querySelector(
			"meta[name=\"title\"]"
		);
		const description: Element | null = window.document.head.querySelector(
			"meta[name=\"description\"]"
		);
		window.document.title = `${this.state.guide.title} - fulfilmentcrowd.dev`;
		title?.setAttribute("content", `${this.state.guide.title} - fulfilmentcrowd.dev`);
		description?.setAttribute("content", view.state.guide.summary);
		next();
		document.body.scrollTop = document.documentElement.scrollTop = 0;
	}

	public getHeadings(): void {
		const content: Array<string> = marked.marked.parse(this.state.guide.content).split("\n");
		this.state.headings = [];
		content.forEach((line: string) => {
			if (line.startsWith("<h1 id=\"") || line.startsWith("<h2 id=\"") || line.startsWith("<h3 id=\"") || line.startsWith("<h4 id=\"") || line.startsWith("<h5 id=\"") || line.startsWith("<h6 id=\"")) {
				const html: HTMLElement = document.createElement("div");
				html.innerHTML = line;
				this.state.headings.push({ title: html.innerText, type: line.startsWith("<h1 id=\"") || line.startsWith("<h2 id=\"") ? "heading" : "subheading", link: `#${html.firstElementChild?.id}` });
			}
		});
	}

	public navigateToHeading(link: string | undefined): void {
		location.href = `${location.href}#${link}`;
	}

	public setActiveHeading(heading: string): void {
		this.state.activeHeading = heading;
	}
}

export const view: GuideModel = new GuideModel();
