
import { defineComponent } from "vue";
import { ComponentName } from "../constants";

export default defineComponent({
	name: ComponentName.Button,
	props: {
		text: {
			type: String
		}
	}
});
