import { getLocalizedText } from "@/utilities/localization";
import { reactive } from "vue";
import { HomeState } from "./home-state";
import { Guide } from "./home-types";

export class HomeModel {
	// Define state for home page
	public state: HomeState = reactive(new HomeState);

	// Get Translated text
	public getText(key: string): string {
		return getLocalizedText("home", key);
	}

	public init(): void {
		// 
	}

	public guides: Array<Guide> = [
		{
			title: "Create a product",
			slug: "/guides/api/create-a-product",
		},
		{
			title: "Create an order",
			slug: "/guides/api/create-an-order"
		},
		{
			title: "Create a transfer",
			slug: "/guides/api/create-a-transfer"
		},
		{
			title: "Create an API Key",
			slug: "/api/getting-started/create-an-api-key"
		},
		{
			title: "Get Stock Levels",
			slug: "/guides/api/get-stock-levels"
		},
		{
			title: "Subscribe to order updates",
			slug: "/guides/webhooks/subscribe-to-order-updates"
		},
		
	]
}