<template>
  <!-- Hero -->
  <div>
    <div
      class="h-80 px-6 bg-no-repeat bg-center bg-cover"
      style="background-image: url('/hero-bg.svg')"
    >
      <div class="w-full h-52 flex items-center">
        <div class="leading-7">
          <h1 class="text-2xl text-blackcurrant-500 font-rubik font-bold">
            Connect with fulfilmentcrowd
          </h1>
          <p class="text-gray-500">
            Get started below or use the side nav to explore our connections
          </p>
        </div>
      </div>
    </div>
    <div class="px-6 xl:space-x-6 xl:flex -mt-28 space-y-6 xl:space-y-0">
      <div class="xl:w-1/3 w-full h-52 bg-white rounded-md shadow-lg p-8 flex flex-col">
        <div class="flex space-x-3 text-raspberry-500 items-center text-sm font-medium">
          <font-awesome-icon :icon="['far', 'code']" />
          <h1>API</h1>
        </div>
        <h1 class="text-lg text-blackcurrant-500 font-rubik font-bold mt-1">
          Connect through our API
        </h1>
        <div class="mt-auto">
          <router-link to="/api/getting-started/overview">
            <Button text="Get Started" />
          </router-link>
        </div>
      </div>

      <div class="xl:w-1/3 w-full h-52 bg-white rounded-md shadow-lg p-8 flex flex-col">
        <div class="flex space-x-3 text-raspberry-500 items-center text-sm font-medium">
          <font-awesome-icon :icon="['far', 'exchange']" />
          <h1>Webhooks</h1>
        </div>
        <h1 class="text-lg text-blackcurrant-500 font-rubik font-bold mt-1">
          Subscribe to event notifications
        </h1>
        <div class="mt-auto">
          <router-link to="/webhooks/getting-started/overview">
            <Button text="Get Started" />
          </router-link>
        </div>
      </div>

      <div class="xl:w-1/3 w-full h-52 bg-white rounded-md shadow-lg p-8 flex flex-col">
        <div class="flex space-x-3 text-raspberry-500 items-center text-sm font-medium">
          <font-awesome-icon :icon="['far', 'file-alt']" />
          <h1>Guides</h1>
        </div>
        <h1 class="text-lg text-blackcurrant-500 font-rubik font-bold mt-1">
          Read our quick start guides
        </h1>
        <div class="mt-auto">
          <router-link to="/guides/api/create-a-product">
            <Button text="Get Started" />
          </router-link>
        </div>
      </div>
    </div>
  </div>
  <div class="p-8 bg-gray-100">
    <div class="space-y-5">
      <div class="leading-7">
        <h1 class="text-2xl text-blackcurrant-500 font-rubik font-bold">
          Featured Guides
        </h1>
        <p class="text-gray-500">
          Access our range of help quides
        </p>
      </div>
      <div class="grid xl:grid-cols-2 grid-cols-1 gap-6">
        <router-link
          v-for="guide in view.guides"
          :key="guide"
          :to="guide.slug"
        >
          <ListItem
            class="col-span-1"
            :title="guide.title"
            :icon="['far', 'file-alt']"
            icon-class="bg-gray-100 text-gray-500"
          />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted } from "vue";
import { View } from "@/views/constants";
import { HomeModel } from "./home-model";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { isDesktopLayout } from "@/data/providers/device-provider";

export default defineComponent({
	name: View.Home,
	components: {
		FontAwesomeIcon
	},
	setup() {
		const view: HomeModel = new HomeModel();

		onMounted(() => {
			view.init();
		});

		return { view, isDesktopLayout };
	}
});
</script>
