import { createRouter, createWebHistory, NavigationGuardNext, RouteLocationNormalized, Router, RouteRecordRaw } from "vue-router";
import NProgress from "nprogress";
import { View } from "@/views/constants";

import Home from "@/views/home/home.vue";
import Guide from "@/views/guide/guide.vue";
import APIReference from "@/views/api-reference/api-reference.vue";
import ErrorPage from "@/views/404/404.vue";

import { view } from "@/views/guide/guide-model";
import { mainContainer } from "@/main";
 
const routes: Array<RouteRecordRaw> = [
	{
		path: "/",
		name: View.Home,
		component: Home
	},
	{
		path: "/:category/:guide/:section",
		name: View.Guide,
		component: Guide
	},
	{
		path: "/api-reference",
		name: View.APIReference,
		component: APIReference
	},
	{
		path: "/404",
		name: View.ErrorPage,
		component: ErrorPage
	}
];

const router: Router = createRouter({
	history: createWebHistory("/"),
	routes
});

router.beforeEach((to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
	if (!to.matched.length) {
		next("/404");
	}
	else {
		NProgress.start();
		const title: Element | null = window.document.head.querySelector(
			"meta[name=\"title\"]"
		);
		const description: Element | null = window.document.head.querySelector(
			"meta[name=\"description\"]"
		);
		if (to.name === View.Guide) {
			view.setGuide(to, next);
		}
		else {
			window.document.title = "fulfilmentcrowd.dev";
			title?.setAttribute("content", "fulfilmentcrowd.dev");
			description?.setAttribute("content", "Connect & Integrate with fulfilmentcrowd");
			next();
		}
	}
});

router.afterEach(() => {
	NProgress.done();
	if (mainContainer.value) {
		mainContainer.value.scrollTop = 0;
	}
});

export default router;
