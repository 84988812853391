/* eslint-disable @typescript-eslint/ban-types */
import { isSidebarOpen } from "@/components/sidebar/sidebar-model";
import { ref, Ref } from "vue";

export const isDesktopLayout: Ref<boolean> = ref(false);

export function listenForScreenSizeChange(): void {
	let resize: number | undefined;
    
	const onResize: Function = () => {
		if (window.innerWidth > 768) {
			isDesktopLayout.value = true;
			isSidebarOpen.value = true;
		}
		else {
			isDesktopLayout.value = false;
			isSidebarOpen.value = false;
		}
		const appHeight: Function = () => {
			const doc: HTMLElement = document.documentElement;
			doc.style.setProperty("--app-height", `${window.innerHeight}px`);
		};
		appHeight();
	};
    
	onResize();
    
	window.addEventListener("resize", function() {
		clearTimeout(resize);
		resize = setTimeout(onResize, 50);
	});
}