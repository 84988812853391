<template>
  <button class="inline-flex items-center justify-center p-2.5 px-6 text-sm font-medium rounded-full focus:outline-none text-white bg-blackcurrant-500 ring-blackcurrant-400 cursor-pointer focus:ring-2 focus:ring-offset-2 dark:ring-offset-coolGray-800 transition-all hover:bg-opacity-90">
    <h1>{{ text }}</h1>
  </button>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { ComponentName } from "../constants";

export default defineComponent({
	name: ComponentName.Button,
	props: {
		text: {
			type: String
		}
	}
});
</script>
