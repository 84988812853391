<template>
  <div
    v-if="view.state.guide"
    class="w-full items-stretch"
    :class="isDesktopLayout ? 'flex space-x-10 p-10' : ' flex flex-col-reverse'"
  >
    <div
      class="p-6 bg-white w-full shadow-md"
      :class="isDesktopLayout ? 'rounded-md' : ' mt-5'"
    >
      <div class="space-y-5">
        <div class="leading-7">
          <h1
            id="guide-title"
            class="text-2xl text-gray-900 font-rubik font-bold"
          >
            {{ view.state.guide.title }}
          </h1>
          <p class="text-gray-500">
            {{ view.state.guide.summary }}
          </p>
        </div>
        <div
          class="prose !text-gray-500"
          v-html="marked.marked.parse(view.state.guide.content)"
        />
      </div>
    </div>
    <div>
      <div
        class="px-4 pb-4 bg-white sticky top-10 z-10 shadow-md overflow-y-auto"
        :class="isDesktopLayout ? 'w-72 max-h-96 rounded-md' : 'max-h-64'"
      >
        <h1 class="text-gray-900 font-bold font-rubik py-4 sticky top-0 bg-white">
          In this article
        </h1>
        <a
          v-for="heading in view.state.headings"
          :key="heading"
          :href="heading.link"
          @click="view.setActiveHeading(heading.link)"
        >
          <h1
            :class="[heading.type === 'heading' ? 'text-gray-500 pl-4' : 'text-gray-500 pl-8', view.state.activeHeading === heading.link ? 'border-blackcurrant-500 text-blackcurrant-500 font-medium border-l-2' : 'border-gray-300 border-l']"
            class="text-sm hover:bg-gray-10 py-1.5 cursor-pointer"
          >
            {{ heading.title }}
          </h1>
        </a>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted } from "vue";
import { View } from "@/views/constants";
import { view } from "./guide-model";
import * as marked from "marked";
import hljs from "highlight.js";
import "highlight.js/styles/vs2015.css";
import { isDesktopLayout } from "@/data/providers/device-provider";

export default defineComponent({
	name: View.Guide,
	setup() {
		marked.marked.setOptions({
			highlight: function(code: string) {
				return hljs.highlightAuto(code).value;
			}
		});

		onMounted(() => {
			view.init();
		});

		return { view, marked, isDesktopLayout };
	}
});
</script>
