<template>
  <div
    v-if="isDesktopLayout"
    class="h-16 w-full bg-gray-100 flex items-center border-b border-gray-300 px-6 space-x-5"
  >
    <div
      class="flex items-center space-x-4 text-gray-500 flex-1 cursor-pointer hover:text-gray-400 transition-colors h-16"
      @click="isSearchOpen = true"
    >
      <font-awesome-icon :icon="['far', 'search']" />
      <h1 class="text-sm">
        Search for anything...
      </h1>
    </div>
    <router-link to="/api-reference">
      <Button text="API Reference" />
    </router-link>
  </div>
  <div
    v-else
    class="h-16 bg-white px-6 flex items-center justify-between flex-none border-b border-gray-50"
  >
    <img
      src="/fc-dev-logo.svg"
      class="h-7 cursor-pointer"
      @click="this.$router.push(`/`)"
    >
    <div class="flex items-center space-x-4">
      <font-awesome-icon
        :icon="['far', 'search']"
        class="hover:opacity-80 transition-opacity"
        @click="isSearchOpen = true"
      />
      <font-awesome-icon
        :icon="isSidebarOpen ? ['far', 'times'] : ['far', 'bars']"
        class="hover:opacity-80 transition-opacity"
        @click="isSidebarOpen = !isSidebarOpen"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { ComponentName } from "../constants";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { isDesktopLayout } from "@/data/providers/device-provider";
import { isSidebarOpen } from "@/components/sidebar/sidebar-model";
import { isSearchOpen } from "@/components/search/search-model";

export default defineComponent({
	name: ComponentName.Header,
	components: {
		FontAwesomeIcon
	},
	setup() {
		return { isDesktopLayout, isSidebarOpen, isSearchOpen };
	}
});
</script>
