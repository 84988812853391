<template>
  <div class="h-40 border font-rubik border-gray-50 shadow-sm hover:shadow-md cursor-pointer rounded-md transition-all col-span-1 flex items-center justify-center">
    <div class="text-center space-y-0.5">
      <h1 class="text-xl font-medium">
        {{ title }}
      </h1>
      <h2 class="text-blue-500">
        {{ subtitle }}
      </h2>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { ComponentName } from "../constants";

export default defineComponent({
	name: ComponentName.Tile,
	props: {
		title: {
			type: String
		},
		subtitle: {
			type: String
		},
	}
});
</script>
