
import { defineComponent } from "vue";
import { ComponentName } from "../constants";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { isDesktopLayout } from "@/data/providers/device-provider";
import { isSidebarOpen } from "@/components/sidebar/sidebar-model";
import { isSearchOpen } from "@/components/search/search-model";

export default defineComponent({
	name: ComponentName.Header,
	components: {
		FontAwesomeIcon
	},
	setup() {
		return { isDesktopLayout, isSidebarOpen, isSearchOpen };
	}
});
