
import { defineComponent } from "vue";
import { ComponentName } from "../constants";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default defineComponent({
	name: ComponentName.ListItem,
	components: {
		FontAwesomeIcon
	},
	props: {
		title: {
			type: String
		},
		icon: {
			type: Array
		},
		iconClass: {
			type: String
		}
	}
});
