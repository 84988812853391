<template>
  <transition
    enter-active-class="transition duration-300 ease-out"
    enter-from-class="opacity-0"
    enter-to-class="opacity-100"
    leave-active-class="transition duration-150 ease-in"
    leave-from-class="opacity-100"
    leave-to-class="opacity-0"
  >
    <div
      v-if="isSearchOpen"
      class="absolute inset-0 w-screen h-screen transition-opacity bg-gray-900 cursor-pointer bg-opacity-40"
      @click="isSearchOpen = false; searchTerm = ''"
    />
  </transition>

  <transition
    enter-active-class="transition duration-300 ease-out"
    enter-from-class="transform scale-95 opacity-0"
    enter-to-class="transform scale-100 opacity-100"
    leave-active-class="transition duration-150 ease-in"
    leave-from-class="transform scale-100 opacity-100"
    leave-to-class="transform scale-95 opacity-0"
  >
    <div
      v-if="isSearchOpen"
      class="absolute inset-0 z-[70] flex justify-center w-full h-full pointer-events-none"
    >
      <div class="w-full max-w-lg px-4 pt-16 transition-all transform sm:pt-24">
        <div class="overflow-hidden bg-white rounded-lg shadow-md pointer-events-auto">
          <div class="relative flex items-center pr-4 shadow-sm">
            <input
              ref="search"
              v-model="searchTerm"
              class="flex-auto py-4 pl-4 pr-12 text-base text-gray-600 placeholder-gray-500 bg-transparent appearance-none -mr-9 sm:text-sm focus:outline-none"
              placeholder="Search for anything..."
            >
          </div>
          <ul
            v-if="searchTerm"
            class="text-sm font-medium overflow-y-auto border-t border-gray-50 divide-y divide-gray-50 rounded-b-lg max-h-[18.375rem]"
          >
            <li
              v-for="result in filteredResults"
              :key="result"
              tabindex="-1"
              @click="component.open(result.slug)"
            >
              <a
                class="flex justify-between p-4 transition-all cursor-pointer hover:bg-gray-10 group"
              >
                <span class="text-blackcurrant-500 font-rubik transition-colors group-hover:text-blue-500 whitespace-nowrap">{{ result.title }}</span>
                <span class="ml-4 text-right text-gray-500">{{ result.category }}</span>
              </a>
            </li>
          </ul>
          <div
            class="p-16 text-center bg-gray-10"
            :class="filteredResults.length === 0 ? 'block' : 'hidden'"
          >
            <h2 class="mb-2 font-semibold text-blackcurrant-500 font-rubik">
              No results found
            </h2>
            <p class="text-sm text-gray-500">
              We can’t find anything with that term at the moment, try searching something else.
            </p>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { Guide } from "@/views/guide/guide-types";
import { computed, defineComponent, ComputedRef, Ref, ref, onMounted, watchEffect, nextTick } from "vue";
import { ComponentName } from "../constants";
import { SearchModel, searchTerm, isSearchOpen } from "./search-model";

export default defineComponent({
	name: ComponentName.Search,
	setup() {
		const component: SearchModel = new SearchModel();
		const search: Ref = ref();

		const filteredResults: ComputedRef<Array<Guide>> = computed((): Array<Guide> => {
			let filtered: Array<Guide> = component.guides;
			if (searchTerm.value) {
				filtered = filtered.filter((result: Guide) => {
					return result.title.toLowerCase().includes(searchTerm.value.toLowerCase()) || result.summary.toLowerCase().includes(searchTerm.value.toLowerCase()) || result.category.toLowerCase().includes(searchTerm.value.toLowerCase());
				});
			}
			return filtered;
		});

		onMounted(() => {
			watchEffect(() => {
				if (isSearchOpen.value) {
					nextTick(() => {
						search.value.focus();
					});
				}
			});
		});

		return { component, filteredResults, searchTerm, search, isSearchOpen };
	},
});
</script>
