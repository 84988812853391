<template>
  <div class="flex h-screen w-screen bg-gray-100 overflow-hidden">
    <Sidebar />
    <div class="h-full w-full flex flex-col">
      <Header />
      <div
        ref="mainContainer"
        class="w-full h-full overflow-y-auto"
      >
        <slot />
      </div>
    </div>
  </div>
  <Search />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mainContainer } from "@/main";

export default defineComponent({
	setup() {
		return { mainContainer };
	},
});
</script>
