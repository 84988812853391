
import { defineComponent, onMounted } from "vue";
import { View } from "@/views/constants";
import { HomeModel } from "./home-model";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { isDesktopLayout } from "@/data/providers/device-provider";

export default defineComponent({
	name: View.Home,
	components: {
		FontAwesomeIcon
	},
	setup() {
		const view: HomeModel = new HomeModel();

		onMounted(() => {
			view.init();
		});

		return { view, isDesktopLayout };
	}
});
