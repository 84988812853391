import router from "@/router";
import { Guide } from "@/views/guide/guide-types";
import { ref, Ref } from "@vue/reactivity";

export const searchTerm: Ref<string> = ref("");
export const isSearchOpen: Ref<boolean> = ref(false);

export class SearchModel {
	public requireAll(requireContext: __WebpackModuleApi.RequireContext): Array<Guide> {
		return requireContext.keys().map(require.context("@/content", true, /.json$/)) as Array<Guide>;
	}
		
	public guides: Array<Guide> = this.requireAll(require.context("@/content", true, /.json$/));

	public open(link: string | undefined): void {
		if (link) {
			router.push(link);
			isSearchOpen.value = false;
			searchTerm.value = "";
		}
	}
}