import { Category, Guide } from "@/data/providers/categories-provider";
import { isDesktopLayout } from "@/data/providers/device-provider";
import router from "@/router";
import { Ref, ref } from "vue";

export const isSidebarOpen: Ref<boolean> = ref(false);

export class SidebarModel {
	public init(): void {
		this.openDropdown();
		router.afterEach(() => {
			this.openDropdown();
		});
	}

	public navigation: Array<Category> = [
		{
			title: "API",
			slug: "api",
			icon: ["far", "code"],
			dropdown: false,
			guides: [
				{
					title: "Getting Started",
					slug: "getting-started",
					dropdown: false,
					sections: [
						{
							title: "Overview",
							slug: "overview"
						},
						{
							title: "Create an API key",
							slug: "create-an-api-key"
						},
						{
							title: "Send a request",
							slug: "send-a-request"
						}
					]
				}
			]
		},
		{
			title: "Webhooks",
			slug: "webhooks",
			icon: ["far", "exchange"],
			dropdown: false,
			guides: [
				{
					title: "Getting Started",
					slug: "getting-started",
					dropdown: false,
					sections: [
						{
							title: "Overview",
							slug: "overview"
						},
						{
							title: "Create a webhook",
							slug: "create-a-webhook"
						},
						{
							title: "Testing your webhook",
							slug: "testing-your-webhook"
						}
					]
				}
			]
		},
		{
			title: "Guides",
			slug: "guides",
			icon: ["far", "file-alt"],
			dropdown: false,
			guides: [
				{
					title: "API",
					slug: "api",
					dropdown: false,
					sections: [
						{
							title: "Create a product",
							slug: "create-a-product"
						},
						{
							title: "Create an order",
							slug: "create-an-order"
						},
						{
							title: "Create a transfer",
							slug: "create-a-transfer"
						},
						{
							title: "Get stock levels",
							slug: "get-stock-levels"
						}
					]
				},
				{
					title: "Webhooks",
					slug: "webhooks",
					dropdown: false,
					sections: [
						{
							title: "Subscribe to order updates",
							slug: "subscribe-to-order-updates"
						}
					]
				}
			]
		}
	]

	public openDropdown(): void {
		if (router.currentRoute.value.params.category && router.currentRoute.value.params.guide) {
			const category: Category = this.navigation.filter((category: Category) => {
				return category.slug === router.currentRoute.value.params.category;
			})[0];

			const guide: Guide = category.guides.filter((guide: Guide) => {
				return guide.slug === router.currentRoute.value.params.guide;
			})[0];

			category.dropdown = true;
			guide.dropdown = true;
		}
	}

	public openLink(link: string, collapse?: boolean): void {
		router.push(link);
		if (!isDesktopLayout.value) {
			isSidebarOpen.value = false;
		}
		if (collapse) {
			this.navigation.forEach((category: Category) => {
				category.dropdown = false;
				category.guides.forEach((guide: Guide) => {
					guide.dropdown = false;
				});
			});
		}
	}
}