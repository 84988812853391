
import { defineComponent } from "vue";
import DefaultLayout from "./layouts/default-layout.vue";
import BlankLayout from "./layouts/blank-layout.vue";

export default defineComponent({
	name: "App",
	components: {
		DefaultLayout,
		BlankLayout
	}
});
