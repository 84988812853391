
import { defineComponent } from "vue";
import { ComponentName } from "../constants";

export default defineComponent({
	name: ComponentName.Tile,
	props: {
		title: {
			type: String
		},
		subtitle: {
			type: String
		},
	}
});
