<template>
  <div class="h-40 col-span-1 shadow-sm bg-gray-100 animate-pulse rounded-md" />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { ComponentName } from "../constants";

export default defineComponent({
	name: ComponentName.TileSkeleton
});
</script>
