import { App } from "vue";
import { ComponentName } from "./constants";

import Sidebar from "@/components/sidebar/sidebar.vue";
import Tile from "@/components/tile/tile.vue";
import TileSkeleton from "@/components/tile/tile-skeleton.vue";
import Hero from "@/components/hero/hero.vue";
import ListItem from "@/components/list-item/list-item.vue";
import Header from "@/components/header/header.vue";
import Button from "@/components/button/button.vue";
import Search from "@/components/search/search.vue";

export default {
	install(app: App): void {
		app.component(ComponentName.Sidebar, Sidebar);
		app.component(ComponentName.Tile, Tile);
		app.component(ComponentName.TileSkeleton, TileSkeleton);
		app.component(ComponentName.Hero, Hero);
		app.component(ComponentName.ListItem, ListItem);
		app.component(ComponentName.Header, Header);
		app.component(ComponentName.Button, Button);
		app.component(ComponentName.Search, Search);
	}
};