<template>
  <transition
    enter-active-class="duration-500 ease-in-out"
    enter-from-class="opacity-0"
    enter-to-class="opacity-100"
    leave-active-class="duration-500 ease-in-out"
    leave-from-class="opacity-100"
    leave-to-class="opacity-0"
  >
    <div
      v-if="isSidebarOpen && !isDesktopLayout"
      class="absolute bg-gray-900 bg-opacity-40 w-full h-full"
      @click="isSidebarOpen = false"
    />
  </transition>
    
  <transition
    :enter-active-class="!isDesktopLayout ? 'transition duration-500 ease-in-out transform' : ''"
    :enter-from-class="!isDesktopLayout ? '-translate-x-full' : ''"
    :enter-to-class="!isDesktopLayout ? '-translate-x-0' : ''"
    :leave-active-class="!isDesktopLayout ? 'transition duration-500 ease-in-out transform' : ''"
    :leave-from-class="!isDesktopLayout ? '-translate-x-0' : ''"
    :leave-to-class="!isDesktopLayout ? '-translate-x-full' : ''"
  >
    <div
      v-if="isSidebarOpen"
      class="w-72 flex-none h-full border-r border-gray-300 bg-white z-50 flex flex-col"
      :class="!isDesktopLayout ? 'absolute inset-0' : ''"
    >
      <div class="h-16 w-72 flex-none flex items-center px-6 border-b border-gray-300">
        <img
          src="/fc-dev-logo.svg"
          class="cursor-pointer"
          :class="isDesktopLayout ? 'h-8' : 'h-7'"
          @click="component.openLink(`/`, true)"
        >
      </div>
      <div class="w-full h-full bg-white overflow-y-auto">
        <div
          v-for="category in component.navigation"
          :key="category"
          :class="category.dropdown ? 'border-blackcurrant-500' : 'border-transparent'"
          class="border-l-4"
        >
          <div class="transition-colors cursor-pointer border-b border-gray-300">
            <div
              class="flex items-center hover:bg-gray-10 py-6 px-5 font-rubik"
              :class="[category.dropdown ? 'text-blackcurrant-500' : 'hover:bg-gray-10 text-gray-500']"
              @click="category.dropdown = !category.dropdown"
            >
              <font-awesome-icon
                v-if="category.icon"
                :icon="category.icon"
                class="mr-4"
              />
              <h1 class="font-medium text-sm">
                {{ category.title }}
              </h1>
              <font-awesome-icon
                :icon="category.dropdown ? ['far', 'chevron-up'] : ['far', 'chevron-down']"
                class="ml-auto"
              />
            </div>
            <div
              v-for="guide in category.guides"
              v-show="category.dropdown === true"
              :key="guide"
              :class="guide.dropdown ? 'mb-6' : ''"
            >
              <div
                class="ml-4 mb-2 flex items-center justify-between py-2 hover:bg-gray-10 px-6"
                @click="guide.dropdown = !guide.dropdown"
              >
                <h1
                  class="text-sm"
                  :class="guide.dropdown ? 'text-blackcurrant-500 font-medium' : 'text-gray-900'"
                >
                  {{ guide.title }}
                </h1>
                <font-awesome-icon
                  :icon="guide.dropdown ? ['far', 'minus'] : ['far', 'plus']"
                  class="ml-auto transition-colors"
                  :class="guide.dropdown ? 'text-blackcurrant-500' : 'text-gray-500'"
                />
              </div>
              <div
                v-if="guide.dropdown"
                class="ml-10"
              >
                <h1
                  v-for="section in guide.sections"
                  :key="section"
                  class="text-sm hover:bg-gray-10 pl-6 py-1.5"
                  :class="this.$router.currentRoute.value.params.category === category.slug && this.$router.currentRoute.value.params.guide === guide.slug && this.$router.currentRoute.value.params.section === section.slug ? 'text-blackcurrant-500 font-medium border-blackcurrant-500 border-l-2' : 'border-gray-300 text-gray-500 border-l'"
                  @click="component.openLink(`/${category.slug}/${guide.slug}/${section.slug}`)"
                >
                  {{ section.title }}
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="!isDesktopLayout"
        class="p-6"
      >
        <Button
          class="w-full"
          text="API Reference"
          @click="component.openLink('/api-reference', true)"
        />
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive } from "vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { SidebarModel, isSidebarOpen } from "./sidebar-model";
import { isDesktopLayout } from "@/data/providers/device-provider";

export default defineComponent({
	components: {
		FontAwesomeIcon
	},
	props: {
		title: {
			type: String
		},
		items: {
			type: Array
		}
	},
	setup() {
		const component: SidebarModel = reactive(new SidebarModel());

		onMounted(() => {
			component.init();
		});

		return { component, isDesktopLayout, isSidebarOpen };
	}
});
</script>
