import { createApp, Ref, ref } from "vue";
import App from "./App.vue";
import router from "./router";
import "@/assets/index.css";
import Components from "@/components/index";
import "@/data/providers/icon-provider";
import { loadPreferredLanguageAsync } from "./utilities/localization";
import { listenForScreenSizeChange } from "./data/providers/device-provider";

export const preferredLanguage: string = "en";
export const mainContainer: Ref<HTMLBodyElement | undefined> = ref();

listenForScreenSizeChange();

loadPreferredLanguageAsync().then(() => {
	createApp(App)
		.use(router)
		.use(Components)
		.mount("#app");
});