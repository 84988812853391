<template>
  <div class="px-8 bg-blue-600 h-16 flex items-center">
    <router-link to="/">
      <img
        src="/logo-white.png"
        class="h-6"
      >
    </router-link>
  </div>
  <div class="w-full bg-blue-500 py-28">
    <div class="w-full max-w-lg mx-auto px-8 text-center">
      <div class="space-y-6">
        <h1 class="text-3xl font-semibold text-white font-rubik">
          {{ type === 'home' ? 'What can we help you with?' : title }}
        </h1>
        <div
          v-if="type === 'home'"
          class="flex items-center w-full h-10"
        >
          <div class="mt-1 relative rounded-md shadow-sm w-full flex items-center">
            <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <font-awesome-icon
                :icon="['far', 'search']"
                class="text-gray-900"
              />
            </div>
            <input
              id="email"
              type="email"
              name="email"
              class="focus:placeholder-gray-700 block w-full pl-10 sm:text-sm rounded-md py-3.5 border-none"
              placeholder="Search for anything..."
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { ComponentName } from "../constants";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default defineComponent({
	name: ComponentName.Tile,
	components: {
		FontAwesomeIcon
	},
	props: {
		type: {
			type: String,
			default: "home"
		},
		title: {
			type: String
		}
	}
});
</script>
