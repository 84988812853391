
import { defineComponent, onMounted } from "vue";
import { View } from "@/views/constants";
import { view } from "./guide-model";
import * as marked from "marked";
import hljs from "highlight.js";
import "highlight.js/styles/vs2015.css";
import { isDesktopLayout } from "@/data/providers/device-provider";

export default defineComponent({
	name: View.Guide,
	setup() {
		marked.marked.setOptions({
			highlight: function(code: string) {
				return hljs.highlightAuto(code).value;
			}
		});

		onMounted(() => {
			view.init();
		});

		return { view, marked, isDesktopLayout };
	}
});
